import request from '@/utils/http'

// 查询角色信息
export function queryList(params) {
  return request({
    url: '/role/findByPage',
    method: 'get',
    params
  })
}

/***
 * 角色修改
 */
 export function roleEdit(params) {
  return request({
    url: '/role/edit',
    method: 'post',
    data: params
  })
}

/***
 * 角色新增
 */
 export function roleSave(params) {
  return request({
    url: '/role/save',
    method: 'post',
    data: params
  })
}

/***
 * 角色启用、禁用
 */
 export function editStatus(params) {
  return request({
    url: '/role/status',
    method: 'get',
    params
  })
}
